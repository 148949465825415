@media screen and (min-width: 2000px) {
    
    .landing-container {
        display: grid;
        grid-template-columns: 20px 10% 50% auto 10%;
        grid-template-rows: 160px auto auto auto 110px;
        padding-left: 30px;
        padding-right: 30px;
      }
    
      header {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-column-end: 6;
        z-index: 101;
        display: grid;
        grid-template-columns: 250px 20% auto ;
        align-items: center;
      
      }
    
      #logo {
        background: url('./../../assets/images/logo/logo_with_people_250.png') no-repeat;
        width: 250px;
        height: 150px;
        grid-row-start: 1;
      }
    
      nav#main-menu.main-menu {
        font-family: $main-font-family;
        color: $dark-font;
        font-weight: 800;
        font-size: 1vw;
        grid-column: 3;
      }
    
     .main-menu  a:hover {
       color: $main-blue;
     }
    
    
      #window-cleaner {
        background: url('./../../assets/images/window_cleaner/window_cleaner_900.jpg') no-repeat;
        grid-row-start: 1;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-end: 7;
        z-index: 100;
        margin-top: 0;
        width: 900px;
        height: 1050px;
      }
    
      .intro {
        grid-row-start: 3;
        grid-column: 4;
        font-size: 2em;
      }
    
      .phone-icon {
        width: 60px;
        grid-column-start: 1;
        grid-column-end: 3;
      }
    
      .contact-data {
        grid-row: 4;
        grid-column: 4;
        font-size: 1.8em;
        
        p {
          margin-bottom: 5px;
    
        }
      }
    
      .social {
        grid-row: 5;
        grid-column: 4;
      }

      
       /*************************************************************************
                              SERVICES
    *************************************************************************/

    .services-container {
      display: grid;
      grid-template-rows:80px 60px 30px 120px 460px 40px 480px 40px;
      grid-template-columns: 2.5vw 45vw 2.5vw 2.5vw 45vw 2.5vw;
      padding-bottom: 0;
      background-color: $main-background;
  }

  #blue-bg {
      background-color: $lighter-blue-bg;
      grid-column-start: 1;
      grid-column-end: 7;
      grid-row-start: 2;
      grid-row-end: 9;
  }

  #services {
    font-size: 3.6em;
    text-align: left;
    grid-row: 2;
  }

  
.domestic-container {
  display: grid;
  grid-column: 5;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-template-rows: auto auto;

}


.commercial-container {
  grid-column: 2;
  grid-row: 5;
  display: grid;
  grid-template-rows: auto auto;
}


.carpet-container {
  grid-column: 4;
  grid-row: 7;
  display: grid;
  grid-template-rows: auto auto;


}

    #domestic-image {
      background: url(./../../assets/images/domestic/domestic_720.jpg) no-repeat;
      background-size: cover;
      width: 45vw;
      height: 400px;
  
  }
  
    #commercial-image {
        background: url(./../../assets/images/commercial/commercial_500.jpg) no-repeat;
        background-size: cover;
        width: 45vw;
        height: 400px;
    }
  
  #carpet-image {
      background: url(./../../assets/images/carpet/carpet_500.jpg) no-repeat;
      background-size: cover;
      width: 45vw;
      height: 400px;
  }

  .services-text {
    font-size: 1.6em;
  }

     /**************************************************************************************
                                      ABOUT
  **************************************************************************************/
  #about-header {
    font-size: 3.6em;
    grid-row: 1;
    grid-column: 2;
  }

  .about-container {
    display: grid;
    grid-template-rows: 200px 100px 500px 200px auto auto;
    grid-template-columns:5% 5% 30% 5% 5% auto 5%;
  }


  #about-wall {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 4;
    
    
  }

  #sprayer {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 3;
    grid-column-end: 5;

  }

  #about-text {
    grid-row: 3;
    grid-column: 6;
    font-size: 1.6em;
  }
  
     /*****************************************************************************************
                                    TESTIMONIALS
  *****************************************************************************************/

  #testimonials {
    grid-template-columns: 5% repeat(22, 1fr) 5%;
    grid-template-rows: 80px 80px 40px auto;
    font-size: 1.6em;
  
  }

  .blue-banner {
    height: 200px;
    width: 100vw;
    
  }

  #testimonials-header {
  
    margin-left: 2.5vw;
    font-size: 3.6em;
  }

  .testimonial-container {
    padding: 30px;
  }

  #test01 {
    grid-column-start: 16;
    grid-column-end: 23;
    grid-row-start: 2;
    grid-row-end: 6;
  }

  #test02 {
    grid-row-start: 3;
    grid-row-end: 7;
    grid-column-start: 3;
    grid-column-end: 10;
    
  }

  #test03 {
    grid-column-start: 11;
    grid-column-end: 17;
    grid-row-start: 5;
    grid-row-end: 9;
  }

  #test04 {
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 8;
    grid-row-end: 13;
  }


 /********************************************************************************
                                    CONTACT
  ********************************************************************************/

  #contact {
    display: grid;
    grid-template-columns: 5% 40% 5% 5% 40% 5%;
    grid-template-rows: 150px 30vh 30vh 30vh 200px 100px;

    h1{
      font-size: 3.6em;
    }
  }

  #contact-form {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 5;
    z-index: 101;

  }

  .contact-form {
    grid-template-columns: 70%;
    gap: 40px;
    
    textarea {
      height: 150px;
    }
  }

  #google-map {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 1;
  }

  #details-container {
    margin-top: 15vh;
    grid-column: 5;
    grid-row: 3;
    margin-left: 20px;
  }

  footer {
    grid-row: 6;
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: left;
    
  }
    
}