@media screen and (min-width: 1000px) and (max-width: 1200px) {


    /*************************************************************************
                                LANDING
    *************************************************************************/


    .landing-container {
      display: grid;
      grid-template-columns: 20px 52% auto 5% 20px;
      grid-template-rows: 100px auto auto auto 70px;
      margin-bottom: 0;
    }
  
    header {
      grid-row-start: 1;
      grid-column-start: 2;
      grid-column-end: 5;
      z-index: 101;
      align-items: center;
      display: grid;
      grid-template-columns: 200px 10% auto;
    }

  
    #logo {
      background: url('./../../assets/images/logo/logo_with_people_200.png') no-repeat;
      width: 200px;
      height: 100px;
      grid-row-start: 1;
      margin-right: 10px;
    }
  
    nav#main-menu.main-menu {
      font-family: $main-font-family;
      color: $dark-font;
      font-weight: 800;
      font-size: 1.2em;
      grid-column: 3;
    }
  
  
    #window-cleaner {
      background: url('./../../assets/images/window_cleaner/window_cleaner_500.jpg') no-repeat;
      grid-row-start: 1;
      grid-column: 2;
      grid-row-end: 6;
      z-index: 100;
      margin-top: 0;
      width: 500px;
      height: 600px;
    }
  
    .intro {
      grid-row-start: 2;
      grid-column: 3;
      font-size: 1rem;
      padding: 20px 40px 0 40px;
      font-size: 1.4em;
    }
  
    .contact-data {
      grid-row: 3;
      grid-column: 3;
  
      
      p {
        margin-bottom: 5px;
      }
    }
  
    .social {
      grid-row: 4;
      grid-column: 3;
    }
  }

  @media (min-width: 1000px) {

    /**********************************************
                NAV BAR FOR LARGER DEVICES
    **********************************************/

    .menu-toggle,
    .main-menu .menu-close {
      display: none;
    }
    
    /* Undo positioning of off-canvas menu */
    .main-menu {
      position: relative;
          left: auto;
          top: auto;
          height: auto;
      display: block;
      margin-left: 50px;
    }
    
    .main-menu ul {
      display: flex;
      
      /* Undo off-canvas styling */
      padding: 0;
      -webkit-box-shadow: none;
         -moz-box-shadow: none;
              box-shadow: none;
      height: auto;
      width: auto;
      background: none;
    }
    
    .main-menu a {
      color:$dark-font;
      border: 0 !important; /* Remove borders from off-canvas styling */
    }
    
    .main-menu a:hover,
    .main-menu a:focus {
      background: none; /* Remove background from off-canvas styling */
    
    }

       
    /*************************************************************************
                              SERVICES
    *************************************************************************/

    .services-container {
      display: grid;
      grid-template-rows: 60px 60px 520px 40px 540px 40px 580px;
      grid-template-columns: 2.5vw 40vw 15vw 40vw 2.5vw;
      background-color: $main-background;
      padding-bottom: 0;
  
  }

  #blue-bg {
    background-color: $lighter-blue-bg;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 8;
}

  #services {
    font-size: 3rem;
    grid-row: 3;
  }

  
.domestic-container {
  display: grid;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-template-rows: auto auto ;

}


.commercial-container {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 4;
  display: grid;
  grid-template-rows: auto auto;
}


.carpet-container {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 6;
  display: grid;
  grid-template-rows: auto auto;


}

    #domestic-image {
      background: url(./../../assets/images/domestic/domestic_720.jpg) no-repeat;
      background-size: cover;
      width: 55vw;
      height: 350px;
  
  }
  
    #commercial-image {
        background: url(./../../assets/images/commercial/commercial_500.jpg) no-repeat;
        background-size: cover;
        width: 55vw;
        height: 350px;
    }
  
  #carpet-image {
      background: url(./../../assets/images/carpet/carpet_500.jpg) no-repeat;
      background-size: cover;
      width: 55vw;
      height: 350px;
  }

  .services-text {
    font-size: 1.6em;
  }

   /**************************************************************************************
                                      ABOUT
  **************************************************************************************/
 

  .about-container {
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 150px 20vw 60vw 10vw auto;
    font-size: 1.6em;
  }

  #about-header {
    font-size: 3em;
  }

   
  /*****************************************************************************************
                                    TESTIMONIALS
  *****************************************************************************************/

  #testimonials {
    grid-template-columns: 5% 40% 10% 40% 5%;
    grid-template-rows: 70px 40px auto 10px auto 10px auto 10px auto;
    font-size: 1.6em;
  }

  #testimonials-header {
    grid-column: 1;
    margin-left: 2.5vw;
  }

  .testimonial-container {
    padding: 30px;
  }
  
  /****************************************************************************************
                                         CONTACT
  ****************************************************************************************/

  #contact  {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: auto;
    justify-items: stretch;


    h1{
    font-size: 3em;
    margin-left: 40px;
    }
  }


    #contact-form {
      grid-row: 2;
      grid-column: 2;
    }

    .contact-form {
      grid-template-columns: 50%;
    }

    #google-map{
      grid-row: 3;
      grid-column: 2;
    }

    #details-container {
      grid-row: 4;
      grid-column: 2;
    }

    footer {
      grid-row: 5;
      grid-column-start: 1;
      grid-column-end: 3;
    }



  }