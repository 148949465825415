
  @media screen and (min-width: 768px) and (max-width: 1000px) {

    /**************************************************************
                               LANDING
    **************************************************************/


    .landing-container {
      display: grid;
      grid-template-columns: 15px 53% auto 15px;
      grid-template-rows: 80px auto auto auto;
      margin-bottom: 10px;
    }
  
    header {
      grid-row-start: 1;
      grid-column-start: 2;
      grid-column-end: 5;
      z-index: 101;
      align-items: center;
      display: grid;
      grid-template-columns: 150px auto 60px;
    }

    .menu-toggle {
      grid-column: 3;
    }
  
    #logo {
      background: url('./../../assets/images/logo/logo_with_people_150.png') no-repeat;
      width: 150px;
      height: 72px;
      grid-row-start: 1;
    }
  
    nav#main-menu.main-menu {
      font-family: $main-font-family;
      color: $dark-font;
      font-weight: 800;
      font-size: 1.2em;
    }
  
  
    #window-cleaner {
      background: url('./../../assets/images/window_cleaner/window_cleaner_400.jpg') no-repeat;
      grid-row-start: 1;
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-end: 5;
      z-index: 100;
      margin-top: 0;
    }
  
    .intro {
      grid-row-start: 2;
      grid-column-start: 3;
    }
  
    .contact-data {
      grid-row: 3;
      grid-column: 3;
      font-size: 1em;
    }
  
    #phone-no {
      margin-bottom: 10px;
    }
  
    .social {
      grid-row: 4;
      grid-column: 3;
    }

    
    /*************************************************************************
                              SERVICES
    *************************************************************************/

    .services-container {
      display: grid;
      grid-template-rows: 140px 570px 40px 600px 40px 580px 40px;
      grid-template-columns: 2.5vw 30vw 35vw 30vw 2.5vw;
      background-color: $main-background;
      padding-bottom: 0;
  
  }

  #blue-bg {
    background-color: $lighter-blue-bg;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 8;
  }

  #services {
    font-size: 3rem;
    grid-row: 2;
  }

  
.domestic-container {
  display: grid;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-template-rows: auto auto ;

}


.commercial-container {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
  display: grid;
  grid-template-rows: auto auto;
}


.carpet-container {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 5;
  display: grid;
  grid-template-rows: auto auto;


}

    #domestic-image {
      background: url(./../../assets/images/domestic/domestic_720.jpg) no-repeat;
      background-size: cover;
      width: 65vw;
      height: 400px;
  
  }
  
    #commercial-image {
        background: url(./../../assets/images/commercial/commercial_500.jpg) no-repeat;
        background-size: cover;
        width: 65vw;
        height: 400px;
    }
  
  #carpet-image {
      background: url(./../../assets/images/carpet/carpet_500.jpg) no-repeat;
      background-size: cover;
      width: 65vw;
      height: 400px;
  }

  .services-text {
    font-size: 1.6em;
  }

   /**************************************************************************************
                                      ABOUT
  **************************************************************************************/
  #about-header {
    font-size: 3em;
  }

  #about-text {
    font-size: 2em;
  }


  
  /*****************************************************************************************
                                    TESTIMONIALS
  *****************************************************************************************/

  #testimonials {
    grid-template-columns: 5% 40% 10% 40% 5%;
    grid-template-rows: 70px 40px auto 10px auto 10px auto 10px auto;
    font-size: 1.4em;
  }



  /****************************************************************************************
                                         CONTACT
  ****************************************************************************************/

  #contact > h1 {
    font-size: 3em;
    margin-left: 40px;
  }
  
  .contact-form {
    grid-template-columns: 60vw;
  }
}