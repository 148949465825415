@import './variables';
@import './components/about/About';
@import './components/landing/Landing';
@import './components/services/Services';
@import './components/testimonials/testimonials';
@import './components/contact/contact';
@import './components/media-queries/devices_586_767';
@import './components/media-queries/devices_768_999';
@import './components/media-queries/devices_1000_1199';
@import './components/media-queries/devices_1200_1999';
@import './components/media-queries/devices_2000';



.App {
  text-align: center;
  background-color: #f2f2f2;
}

