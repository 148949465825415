


$section-header-font:  acier-bat-noir,sans-serif;
$section-font-weight: 400;
$section-font-style: normal;
$main-blue: #3399cc;
$lighter-blue-bg: #7db5d5;
$dark-font: #333;
$light-font: #9fa3a5;
$main-background: #f2f2f2;
$main-font-family: 'Salsa', cursive;
$green: #2dd6e3;