

.landing-container {
    display: grid;
    grid-template-columns: 15px auto 15px;
    grid-template-rows: 70px 410px auto auto auto;
    margin-bottom: 30px;
}

header {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  display: grid;
  grid-template-columns: 100px auto 10%;
  align-items: baseline;
  text-transform: uppercase;
}


#logo {
background: url('./../../assets/images/logo/logo_with_people_100.png') no-repeat;
grid-column-start: 1;
z-index: 100;
margin-top: 5px;
width: 100px;
height: 60px;
}

#window-cleaner {
background: url('./../../assets/images/window_cleaner/window_cleaner_350.jpg') no-repeat;
grid-column-start: 2;
grid-row-start: 2;
grid-row-end: 5;
z-index: 90;
}

.intro {
grid-column-start: 2;
grid-row-start: 3;
grid-column-end: 3;
padding: 10px 10px;
font-family: $main-font-family;
font-size: 1.3rem;
color: $light-font;

b {
    color: $dark-font;
}
}

.contact-data {
grid-row-start: 4;
grid-column-start: 2;
grid-column-end: 3;
align-items: center;
display: grid;
grid-template-columns: 30px 40px auto 30px;

p {
  grid-column-start: 3;
  font-family: $main-font-family;
  color: $dark-font;
  font-size: 1.2em;
  margin-top: 0;
}


#phone-no {
  font-size: 1.8em;
}

}

.phone-icon {
grid-column-start: 2;
width: 40px;
height: auto;

}

.social {
display: grid;
grid-template-columns: auto auto auto;
justify-content: space-between;
grid-column-start: 2;
grid-column-end: 3;
margin-top: 10px;
}

/* Screen reader only */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
  
  /* Button styling */
  .menu-toggle {
    font-size: 2em;
    color: $main-blue;
    grid-column-start: 3;
    align-self: center;
  justify-self: center;
  margin-top: 10px;
  }
  
 
  
  /*
   Default styles + Mobile first
   Offscreen menu style
  */
  .main-menu {
    position: absolute;
    display: none;
    left: -200px;
    top: 0;
    height: 100%;

      overflow-x: visible;
      transition: left 0.3s ease, 
      box-shadow 0.3s ease;
      z-index: 999;
  }
  
  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 2.5em 0 0;
    min-height: 100%;
    width: 200px;
    background: #f2f2f2;
  }
  
  .main-menu a {
    display: block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: $dark-font;
    font-family: $main-font-family;
    text-decoration: none;

  }
  
  .main-menu li:first-child a {
    border-top: 1px solid #383838;
  }
  
  .main-menu a:hover,
  .main-menu a:focus {
    background: $main-background;
    text-decoration: underline;
  }
  
  .main-menu .menu-close {
    position: absolute;
    right: 0;
    top: 0;
  
  }
  
  /*
   On small devices, allow it to toggle...
  */
  /*
   :target for non-JavaScript
   [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
  */
  .main-menu:target,
  .main-menu[aria-expanded="true"] {
    display: block;
    left: 0;
    outline: none;
    -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    box-shadow: 3px 0 12px rgba(0,0,0,.25);
  }
  
  .main-menu:target .menu-close,
  .main-menu[aria-expanded="true"] .menu-close {
    z-index: 1001;
  }
  
  .main-menu:target ul,
  .main-menu[aria-expanded="true"] ul {
    position: relative;
    z-index: 1000;
  }
  
  /* 
   We could us `.main-menu:target:after`, but
   it wouldn't be clickable.
  */
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop{
    position: absolute;
    display: block;  
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background: #000;
    background: rgba(0,0,0,.45);
    cursor: default;
  }
  
  @supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
      position: fixed;
    }
  }
 
 




