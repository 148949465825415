
.about-container {
    z-index: 90;
    padding-left: 15px;
    padding-right: 15px;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-template-rows: 100px 30vw 60vw 10vw auto;
    margin-bottom: 30px;
}

#about-header {
    font-family: $section-header-font;
    color: $lighter-blue-bg;
    font-size: 2.3rem;
    font-weight: 100;
    grid-row-start: 1;
    grid-column: 1;
    margin-left: 10px;
    
}

#about-wall {
    width: 100%;
    background-color: $green;
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column: 2;
}

#sprayer {
    background: url(./../../assets/images/spraying_woman.png) no-repeat center center;
    background-size: contain;
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 6;

}

#about-text {
    grid-row-start: 8;
    grid-column: 2;
    padding: 10px 20px;
    font-family: $main-font-family;
    color: $light-font;
    padding: 0;
}


